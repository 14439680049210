<template>
  <n-timeline>
    <n-timeline-item
      v-for="item in props.params" :key="item"
      :type="item.type"
      :title="item.title"
      :content="item.content"
      :time="item.time"
    />
  </n-timeline>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    'params': Object
  },
  setup (props) {
    return {
      props,
      options: ref(props.params),
      // options: JSON.parse('[{"type": "success", "title": "Success", "content": "content", "time": "2022"}]'),
    }
  }
})
</script>
